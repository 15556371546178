<template>
  <div class="card full-page">
    <div class="card-body inner-scroll">
      <template v-if="hasAccess">
        <div
          v-show="!stripeAccountActivated && stripeAccountActivated !== null && !returnFromSetup"
          class="onboarding"
        >
          <h1>{{ $t('Payments onboarding') }}</h1>

          <div class="row">
            <div class="col-md-7">
              <p>
                {{ $t('payments-onboarding-1') }}
              </p>
              <br /><a :href="onboardingUrl" class="btn btn-default btn-lg toddl-button">{{
                $t('Set up payments')
              }}</a>
            </div>
            <div class="col-md-5">
              <img :src="require('@/assets/images/illustration/demand.svg')" />
            </div>
          </div>
        </div>

        <div v-if="returnFromSetup" class="returnFromSetup">
          <h2>{{ $t('Your Stripe account was successfully set up') }}</h2>
          <p>{{ $t('Please wait a few seconds for this page to refresh') }}</p>
        </div>

        <div v-if="stripeAccountActivated && !returnFromSetup" class="onboarding">
          <div class="feat-desc">
            <p>{{ $t('desc_feat_payments_1') }}</p>
          </div>
          <div v-if="payments.length == 0 && subscriptions.length == 0" class="no-results">
            <h3>{{ $t('No payments received') }}</h3>
          </div>
          <div v-if="payments.length || subscriptions.length">
            <b-tabs content-class="mt-2">
              <b-tab :title="$t('Payments')" active>
                <h2>{{ $t('Payments') }}</h2>
                <div class="filter-table float-right w-25 mb-2">
                  <feather-icon
                    style="color: #6bc4c5; display: inline; margin-top: 0.75em"
                    size="16"
                    icon="SearchIcon"
                    class="mr-50 float-left"
                  />
                  <input
                    v-model="search"
                    style="display: inline; width: 85% !important"
                    type="text"
                    class="form-control inline"
                    :placeholder="$t('Search')"
                  />
                </div>
                <b-table
                  striped
                  hover
                  responsive
                  :filter="search"
                  class="position-relative"
                  :fields="[
                    { date: { label: this.$t('Date') } },
                    { name: { label: this.$t('Name') } },
                    { email: { label: this.$t('Email') } },
                    { phone: { label: this.$t('Phone') } },
                    { activityName: { label: this.$t('Activity Name') } },
                    { amount: { label: this.$t('Amount') } },
                    { status: { label: this.$t('Status') } },
                  ]"
                  :items="payments"
                >
                  <template #cell(status)="data">
                    <div class="w-100 text-truncate">
                      {{ $t(`payment_status_${data.value}`) }}
                    </div>
                  </template>
                </b-table>
              </b-tab>
              <b-tab :title="$t('Subscriptions')">
                <b-table
                  striped
                  hover
                  responsive
                  :filter="search"
                  class="position-relative"
                  :items="subscriptions"
                />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </template>
      <div v-else class="row">
        <div class="col-md-5">
          <p class="feature-text">{{ $t('blocked_feat_payments_1') }}</p>
        </div>
        <div class="col-md-7">
          <iframe
            class="payments-video"
            src="https://www.youtube.com/embed/co9BC7l-tKM?modestbranding=1&rel=0&color=white"
            width="560"
            height="315"
            title="payments pro video"
            frameborder="0"
            allowfullscreen
          />
        </div>
      </div>
    </div>
    <div v-if="!hasAccess" class="card-footer footer-shadow">
      <router-link to="/subscribe" class="btn btn-toddl">
        {{ $t('upgrade_plan_cta') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { BTable, BTab, BTabs } from 'bootstrap-vue'

import userCan from '@/libs/user-can'
import PaymentService from '@/services/PaymentService'

export default {
  name: 'Messages',
  components: {
    BTable,
    BTab,
    BTabs,
  },
  data() {
    return {
      PaymentService: new PaymentService(),
      onboardingUrl: null,
      payments: [],
      subscriptions: [],
      stripeAccountActivated: null,
      stripeAccount: null,
      returnFromSetup: false,
      search: null,
    }
  },
  computed: {
    hasAccess: function () {
      return userCan(this.$store.state.auth.user.plan, 'payments')
    },
  },
  mounted() {
    if (this.$route.query.return === 'true') {
      this.returnFromSetup = true
      setTimeout(() => {
        window.location.href = '/payments'
      }, 3000)
    }
    if (this.hasAccess) {
      this.getData()
    }
  },
  methods: {
    async getData() {
      const res = await this.PaymentService.onboarding(this.$store.state.auth.user.slug, {
        callback: this.$route.query.return === 'true',
      })
      this.setupAccountStatus(res)
      this.onboardingUrl = res.url
    },
    setupAccountStatus: function (payload) {
      this.payments = []
      this.stripeAccountActivated = payload.acct.payouts_enabled
      this.stripeAccount = payload.acct
      if (payload.mergedCharges) {
        payload.mergedCharges.forEach(el => {
          if (el) {
            let d = new Date(0)
            d.setUTCSeconds(el.date)
            let displayDate = d.toLocaleString('es-es')
            this.payments.push({
              date: displayDate.substring(0, displayDate.length - 3),
              activityName: el.activity_name,
              name: el.name,
              email: el.email,
              phone: el.phone,
              id: el.id,
              amount: (el.amount / 100).toFixed(2) + ' EUR',
              fee: ((el.amount / 100) * 0.05).toFixed(2) + ' EUR',
              status: el.status,
              paid: el.paid,
            })
          }
        })
      }
      payload.subscriptions.forEach(s => {
        this.subscriptions.push(s)
      })
    },
  },
}
</script>

<style scoped>
.feature-text {
  font-size: 1.2rem;
  color: #333;
}

.toddl-button {
  background-color: var(--primary);
  color: #fff;
  margin-top: 2em;
}

form {
  min-width: 500px;
  padding-top: 3em;
  align-self: center;
}

input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 90%;
  background: white;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

.payments-video {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
}

@media (max-width: 648px) {
  .payments-video {
    margin-top: 3em;
    max-width: 100%;
  }
}
</style>
